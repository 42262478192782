<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"
              ><i class="fa fa-home"></i
            ></b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'ProductList' }"
              >商品管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>設定商品照片</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center" style="flex-direction: column">
          <h4 class="font-weight-bold">設定商品照片</h4>
        </div>
      </div>

      <div class="mb-4" v-if="product != null">
        {{ product.title }}
      </div>

      <VueDraggable
        class="list-unstyled s-image-list mb-5"
        handle=".s-draggable"
        tag="ul"
        v-model="images"
        :move="checkMove"
      >
        <li
          class="s-image-list-item s-draggable"
          :key="i"
          v-for="(image, i) in images"
        >
          <img class="s-cropped-image" :src="image" />

          <!-- 發現手機板chrome 的click 似乎與draggable 處理event 衝突, 先簡單用touchstart 解掉 -->
          <button
            class="btn btn-danger s-delete-btn"
            type="button"
            v-show="canClick"
            @click="deleteImage(i)"
            @touchstart="deleteImage(i)"
          >
            Ｘ
          </button>
        </li>
        <li class="s-image-list-item" slot="footer" v-if="canUpload">
          <ImageSelector @input="images.push($event)">
            <template #trigger="{ openFileSelector }">
              <button
                class="btn s-trigger-btn"
                :disabled="!canClick"
                @click="openFileSelector"
              >
                <template v-if="canClick">
                  <div class="add-image-icon">＋</div>
                  <div>新增</div>
                </template>
                <template v-else> 上傳中... </template>
              </button>
            </template>
          </ImageSelector>
        </li>
      </VueDraggable>
      <div class="d-flex justify-content-center">
        <!-- <b-button class="mr-3" variant="outline-success" @click="saveDraft">存成草稿</b-button> -->
        <!-- <b-button variant="success" @click="save">確定開團</b-button> -->
        <b-button variant="success" :disabled="!canClick" @click="gotoProductList"
          >回商品列表</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import useVuelidate from "@vuelidate/core";
import VueDraggable from "vuedraggable";
import ImageSelector from "@/components/ImageSelector";
import productApi from "@/apis/product";

export default {
  components: { VueDraggable, ImageSelector },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data: () => ({
    currentProductId: null,
    branchId: null,
    product: null,
    images: [],
    productOptions: null,
    canClick: true,
    canUpload: true,
  }),
  computed: {
    ...mapState("general", {
      currentBranch: (state) => state.currentBranch,
    }),
    ...mapGetters("route", ["routeQuery"]),
  },
  created: function () {
    this.currentProductId = this.$route.params.product_id;
    this.getProductDetail(this.currentProductId, true);
  },
  watch: {
    async images(val) {
      const last = val[val.length - 1];
      if (last) {
        this.canClick = false;
        if (last.startsWith("data:image/jpeg;base64")) {
          await this.handleImagesChange(last);
          this.canClick = true;
        } else {
          this.getProductDetail(this.currentProductId);
          await productApi.sortImages(this.currentProductId, {
            image_urls: this.images,
          });
          this.canClick = true;
        }
      } else {
        await productApi.sortImages(this.currentProductId, {
          image_urls: [],
        });
        this.canClick = true;
      }
    },
  },
  methods: {
    // 上傳中的時候，不要讓他作任何動作，不可移動照片
    checkMove() {
      return this.canClick;
    },

    async handleImagesChange(imageBase64) {
      try {
        await productApi.uploadImageByBase64(
          this.currentProductId,
          imageBase64
        );

        this.getProductDetail(this.currentProductId, true);
      } catch (err) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "上傳圖片失敗",
        });
      }
    },

    async getProductDetail(id, isUpdateImages = false) {
      const { data } = await productApi.getProduct(id);
      this.product = data.data;
      if (isUpdateImages && this.product) {
        this.images = this.product.images.map((img) => img.pic_url);
      }
    },
    deleteImage(index) {
      this.images.splice(index, 1);
    },
    gotoProductList() {
      const from = this.$route.query.from
      if (from === 'ProductList') {
        this.$router.push({ name: 'ProductList', query: this.routeQuery })
      } else if (from === 'CoreGroupbuyingsProductList') {
        this.$router.push({ name: 'CoreGroupbuyingsProductList', query: this.routeQuery })
      } else {
        this.$router.push({ name: "ProductList", query: this.routeQuery });
      }
    },
  },
};
</script>

<style scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: calc((100% / 3) - 0.75rem * 2);
  border: 1px solid #ddd;
  margin: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.add-image-icon {
  font-weight: 800;
  margin-bottom: 8px;
}
</style>
